.detect-camera-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.video-container {
  position: relative;
  width: 600px;
  height: 600px;
}

.video-stream,
.detection-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
